import React from 'react'
// Shield Components
import USBButton from '@usb-shield/react-button'
import '@usb-shield/react-modal/dist/library/styles/index.css'

import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useProductsQuery } from '@/modules/credentials/services/sandbox-projects.query'
import { SandboxEnabledProduct } from '@/modules/api/sandbox-apigee/products/sandbox-list/aem-product.model'
import addToProjectModal from '@/modules/products/components/AddToProjectCTA/addToProjectComponent.content.json'
import AddToProjectCTA from '@/modules/products/components/AddToProjectCTA/AddToProjectCTA'
import PromoteCTA from '@/modules/credentials/projects/components/PromoteCTA/PromoteCTA'
import { useGetUserInfoClientSide } from '@/utils/user/user.util'
import styles from './productHeroButtonWrapper.module.scss'
import { UserOrganization } from '@/models/user.model'
import { useCookies } from 'react-cookie'

interface ApiProductDetails {
  productName: string
  productVersion: string
  productId: string
}

export function ProductHeroButtonLink(sideLinks: any) {

  if (!Array.isArray(sideLinks) || sideLinks.length === 0) {
    return null
  } else {

    let docButtonLink: any = null
    let documentationLinkSet: boolean = false

    sideLinks.forEach((link) => {
      if (link.metaData.fileType !== 'download' && !documentationLinkSet) {
        // use first non-download link as documentation link
        const docButtonLink1 = '/' + link.path.replace(/^\/|\/$/g, '').split('/').pop()
        documentationLinkSet = true
        docButtonLink = {
          "link": docButtonLink1,
          "metaData": link.metaData
        }
        return
      }
    })

    return docButtonLink
  }
}

const ProductHeroButtonWrapper = ({
  apiProductDetails,
  enableDocumentationBtn,
  promoteSuccessHandler,
  sideNavData
}: {
  apiProductDetails: ApiProductDetails
  enableDocumentationBtn?: boolean,
  documentLink?: string | null
  promoteSuccessHandler: () => void
  sideNavData: any
}) => {
  const [cookies] = useCookies(['locale'])

  const locale = cookies.locale || process.env.DEFAULT_LOCALE

  const router = useRouter()
  let enableAddToProjectBtn = false
  const { data: session, status } = useSession()
  const verifiedOrg: UserOrganization | null | undefined =
    session?.decodedAccessToken?.organization
  const { useSandboxEnabledProductsQuery } = useProductsQuery()
  const { error: productsError, data: sandboxProductsList } =
    useSandboxEnabledProductsQuery()
  const { userInfo } = useGetUserInfoClientSide()
  let filteredProducts: SandboxEnabledProduct[] = []

  // Add to project render
  let addToProjectBtnToRender = <></>

  if (!productsError && sandboxProductsList) {
    filteredProducts =
      sandboxProductsList &&
      sandboxProductsList.filter(
        (p: SandboxEnabledProduct) => p.slug === apiProductDetails.productId
      )
  }

  if(filteredProducts.length !== 0) {
    enableAddToProjectBtn = true
  }

  const sandboxProductInfo = filteredProducts[0] //Todo: Replace array index with map
  const addToProjectModalContent: any = addToProjectModal.content
  const productDetails = { productName: apiProductDetails.productName }

  // Documentation button render
  let documentationButtonRender = <></>

  // set documentation link
  const documentationLink = ProductHeroButtonLink(sideNavData)
  if (status !== 'loading') {

    if (documentationLink) {
      const localeVal = router.asPath.split('/').length === 5 ? router.asPath.split('/')[2] + '/' : ''
      const gettingStartedLink =
        '/products/' +
        localeVal +
        apiProductDetails.productId +
        '/' +
        apiProductDetails.productVersion +
        documentationLink.link
      if (session && documentationLink.metaData.restricted && documentationLink.metaData.protected && !verifiedOrg && process.env.FEATURE_DEVELOPER_DASHBOARD !== 'false') {
        documentationButtonRender = (
          <PromoteCTA ctaText={'Documentation'} type={'secondaryButton'} />
        )
      } else if (session && documentationLink.metaData.fileType == 'download') {
        documentationButtonRender = (
          <USBButton
            ctaStyle="standard"
            emphasis="subtle"
            size="medium"
            spacing={{ margin: 0 }}
            addClasses={styles.documentationBtn}
            dataTestId="documentationButton"
            handleClick={() => {
              router.push(gettingStartedLink)
            }}
          >
            {' '}
            Documentation
          </USBButton>
        )
      } else {
        documentationButtonRender = (
          <USBButton
            ctaStyle="standard"
            emphasis="subtle"
            size="medium"
            spacing={{ margin: 0 }}
            addClasses={styles.documentationBtn}
            dataTestId="documentationButton"
            handleClick={() => {
              router.push(gettingStartedLink)
            }}
          >
            {' '}
            Documentation
          </USBButton>
        )
      }
    }


    // Add to Project button render
    if (!session) {
      addToProjectBtnToRender = (
        <USBButton
          dataTestId="addToProjectBtnLogin"
          type="button"
          ctaStyle="standard"
          emphasis="heavy"
          size="medium"
          spacing={{ margin: 0 }}
          addClasses={styles.addProjectBtn}
          handleClick={() => {
            router.push({
              pathname: '/login',
              query: { callbackUrl: router.asPath },
            })
          }}
        >
          {addToProjectModalContent.modalTitle}
        </USBButton>
      )
    } else {
      if (!productsError) {
        // If the user is logged in and the product has active sandbox and product is restricted and user has a verified org
        // - open add to project modal
        const userOrgRestrictedPrivateProducts =
          userInfo?.restrictedPrivateProducts ?? []

        let sandboxVisibility: any = ''

        // Check whether user's org has access to the product
        const checkRestrictedPrivateProduct = (
          restrictedPrivateProducts: any,
          sandboxVisibility: any
        ) => {
          if (sandboxVisibility === 'restricted-private') {
            for (const element of restrictedPrivateProducts) {
              let restrictedPrivateProductsEntries: any =
                Object.entries(element).flat()
              if (
                restrictedPrivateProductsEntries[0] ===
                  sandboxProductInfo.slug &&
                restrictedPrivateProductsEntries[1]?.[locale].includes(sandboxProductInfo.versionName)
              ) {
                return true
              }
            }
            return false
          } else {
            return true
          }
        }

        sandboxVisibility = sandboxProductInfo?.visibilityLevel ?? ''

        if (
          sandboxProductInfo?.sandboxStatus &&
          sandboxProductInfo?.sandboxVersionName &&
          sandboxProductInfo?.sandboxVersionName !== '' &&
          ((userInfo?.organization !== null &&
            checkRestrictedPrivateProduct(
              userOrgRestrictedPrivateProducts,
              sandboxVisibility
            )) ||
            (userInfo?.organization === null &&
              !['restricted', 'restricted-private'].includes(
                sandboxVisibility
              )))
        ) {
          let productDetailsData = {
            productName: apiProductDetails.productName,
            productVersion: apiProductDetails.productVersion,
            sandboxProductName: sandboxProductInfo?.sandboxVersionName,
          }

          if (
            sandboxProductInfo?.sandboxStatus !== 'Active' &&
            sandboxProductInfo?.upgradeAvailable
          ) {
            const upgradeData = sandboxProductInfo?.upgradeAvailable
            productDetailsData = {
              productName: apiProductDetails.productName,
              productVersion: upgradeData.versionName,
              sandboxProductName: upgradeData.sandboxVersionName,
            }
          }

          addToProjectBtnToRender = (
            <AddToProjectCTA
              ctaText={addToProjectModalContent.modalTitle}
              productDetails={productDetailsData}
            />
          )
        } else {
          // If product doesn't have active sandbox use the PromoteCTA
          // Promote CTA handles user with verified or not verified org scenarios
          if (process.env.FEATURE_SANDBOX_PROJECTS === 'true') {
            addToProjectBtnToRender = (
              <PromoteCTA
                ctaText={addToProjectModalContent.modalTitle}
                documentDetails={productDetails}
                type={'button'}
                promoteSuccessHandler={promoteSuccessHandler}
              />
            )
          }
        }
      }
    }
  }

  return (
    <>
      {enableAddToProjectBtn && (
        <div className={styles.addProjectBtnWrapper}>
          {addToProjectBtnToRender}
        </div>
      )}

      {enableDocumentationBtn && (
        <div className={styles.addProjectBtnWrapper}>
          {documentationButtonRender}
        </div>
      )}
    </>
  )
}

export default ProductHeroButtonWrapper
