import React from 'react'
import styles from '@/modules/products/ProductDocumentPage/components/TableOfContent/tableOfContent.module.scss'
import Script from 'next/script'
import { tocData } from '@/modules/products/ProductDocumentPage/components/TableOfContent/tableOfContent.model'
import TocMenuItem from '@/modules/products/ProductDocumentPage/components/TableOfContent/TocMenuItem/TocMenuItem'
import Link from 'next/link'

const TableOfContent = ({
  items,
  hashValFromUrl,
  tocTitle,
  activeAnchor,
  onItemClick,
  maxDepth,
  tocTopElement,
}: {
  items: any,
  hashValFromUrl: string
  tocTitle: string
  activeAnchor: string
  onItemClick: any
  maxDepth: number
  tocTopElement: string
}) => {
  return (
    <div className={styles.tableOfContentSectionWrapper}>
      {items.length > 0 && <div className={styles.tableOfContentSectionContent}>
        <Script
          id='table_of_content_script'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
                       var selectedTocTile = "${hashValFromUrl}";
                       if (selectedTocTile) {
                           selectedTocTileClass = selectedTocTile;
                           document.getElementById(selectedTocTileClass)?.classList.add("selectedTocTitle");
                           window.location.href = '#' + selectedTocTile;
                       }

                       document.addEventListener("scroll", (event) => {
                        let scrollPos = document.body.scrollTop || document.documentElement.scrollTop
                        
                        if (scrollPos === 0) {
                          document.getElementById("heading-1")?.classList.add("${styles.selectedTableOfContentTitle}")
                        } else {
                          document.getElementById("heading-1")?.classList.remove("${styles.selectedTableOfContentTitle}")
                        }
                      });
                   `
          }} />
        <p id='tableOfContentSectionTitle' className={styles.tableOfContentSectionTitle}>
          {tocTitle}
        </p>
        <ul className={styles.tableOfContentMenuList} role='list'>
        {process.env.SITE_BRAND === 'elavon' ?
          (<li>
            <Link href='#'>
              <a href={'#'}
                id='heading-1'
                data-testid={'tocLink'}
                onClick={() => {
                  window.scrollTo(0, 0)
                }}
              >
                {tocTopElement}
              </a>
            </Link>
          </li>)
          : null
        }
          {items.map((item: tocData<string>) => {
            return (
              <TocMenuItem
                item={item}
                maxDepth={maxDepth}
                activeAnchor={activeAnchor}
                onItemClick={onItemClick}
              />
            )
          })}
        </ul>
      </div>}
    </div>
  )
}

export default TableOfContent
