import '@usb-shield/react-link/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import { defaultGridOptions } from '@/utils/usb-grid.util'
import styles from '@/modules/products/ProductDocumentPage/DisplayViews/displayViews.module.scss'
import '@usb-shield/react-grid/dist/library/styles/index.css'
import { useRouter } from 'next/router'
import Breadcrumb, { GetBreadcrumbs } from '@/components/Breadcrumb/Breadcrumb'
import USBDropdown from '@usb-shield/react-dropdown'
import { useEffect, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import ProductHeroButtonWrapper from '@/modules/products/components/ProductHeroButtonWrapper/ProductHeroButtonWrapper'
import USBNotification from '@usb-shield/react-notification'
import { buildNestedStructure } from '@/utils/build-nested-list/build-nested-elements.util'
import SideNavMenu from '@/modules/products/ProductDocumentPage/components/SideNavMenu/SideNavMenu'
import { sandboxDropdownChangeCb } from '@/modules/products/ProductDocumentPage/utils/callbacks'
import TableOfContent from '@/modules/products/ProductDocumentPage/components/TableOfContent/TableOfContent'
import { useCookies } from 'react-cookie'
import { getElavonNonUSBreadcrumbs, buildTocMenu, getCustomBreadcrumbs } from '@/modules/products/ProductDocumentPage/utils/productDocumentPage.utils'
import { scrollToElement } from '@/utils/scrollToElement'
import translation from '@/src/i18n'
import { useTranslation } from 'react-i18next';
import { getCookie } from 'cookies-next';


const ReactMarkDown = dynamic(
  () => import('@/components/ReactMarkDown/ReactMarkDown'),
  {
    ssr: false,
  }
)

const threeColsOption = {
  span: null,
  spans: {
    xlarge: 3,
    large: 3,
    medium: 3,
    small: 3,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  padding: 'normal',
  align: 'start',
  justify: 'start',
}

const tenColsOption = {
  span: null,
  spans: {
    xlarge: 10,
    large: 10,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'normal',
  align: 'start',
  justify: 'start',
}
let selectedDropDownValue = '',
  toc: any,
  router: any

// this wil be used in conjuction with pageData.metaData in order to check if
// the page should or should not have a scrollbar
const pageWIthoutLeftScrollBar: string[] = ['Terms', 'Privacy', 'Site news', 'Cookie Policy']

const handleClick = (e: any, selectedTocTile: string) => {
  e.preventDefault()
  let x = document.getElementsByClassName(styles.selectedTocTitle)
  // Remove selected styles for previous target
  if (x.length > 0) {
    x[0].parentElement?.removeAttribute('style')
    x[0].removeAttribute('style')
    x[0]?.classList.remove(styles.selectedTocTitle)
  }
  const eleId = selectedTocTile
    .replace(/[&\/\\#,;+()$~%.'":*?<>{}]/g, '')
    .replace('®-–', '-')
  const ele: any = document.getElementById(eleId)
  const selectedValue = toc.find(
    (res: any) => res.value === ele?.children[0]?.textContent
  )
  selectedDropDownValue =
    selectedValue && selectedValue.value && selectedValue.value.length
      ? selectedValue.value
      : ''
  ele?.children[0]?.classList.add(styles.selectedTocTitle)
  if (x.length == 0) {
    e?.target?.classList.add(styles.selectedTocTitle)
  }
  selectedTocTile = selectedTocTile.replace('_toc', '')
  const id = selectedTocTile
    .replace(/[&\/\\#,;+()$~%.'":*?<>{}]/g, '')
    .replace('®-–', '-')

  if (router) {
    scrollToElement(id)
    router.push('#' + id)
  }
}

const ThreeColLayout = ({
  pageData,
  sideNavData,
  componentToc,
  displayVersions
}: {
  pageData: any
  sideNavData: any
  componentToc: any
  displayVersions: any
}) => {

  let locale = getCookie('locale') ?? process.env.DEFAULT_LOCALE
  const lang = locale?.substring(0, 2)
  const { t, i18n } = useTranslation();
  let hideScrollBar = false;

  // updates to allow for unit testing component.  the toc needs to be available for functions like handleClick()
  toc = componentToc
  
  if (pageWIthoutLeftScrollBar.includes(pageData?.metaData?.pageTitle)) {
    hideScrollBar = true;
  }
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [isScrolled, setIsScrolled] = useState(true)
  const [height, setHeight] = useState(0)


  const pageTitle = process.env.SITE_BRAND === 'elavon'
    ? pageData?.genericSection?.heading
    : pageData?.metaData?.pageTitle;

  const [activeAnchorId, setActiveAnchorId] = useState('')

  if(process.env.SITE_BRAND === 'elavon'){
    useEffect(() => {
      const listenToTOCElements = () => {
        let scrollPos = document.body.scrollTop || document.documentElement.scrollTop

        const markdownContent: any = document.querySelector<HTMLElement>('#markdown-content')
        const anchorElements = markdownContent.querySelectorAll('h2, h3')

        const anchorsArray: any = []
        anchorElements.forEach((anchorElement: any) => {
          anchorsArray.push(anchorElement.previousSibling)
        })

        anchorsArray.forEach((anchor: any) => {
          const anchorOffsetTop = anchor.offsetTop
          const anchorHeight = anchor.offsetHeight + 68 + 96
          
          if (anchorOffsetTop <= scrollPos && anchorOffsetTop + anchorHeight > scrollPos) {
            setActiveAnchorId(anchor.id)
          } 

          if (scrollPos === 0) {
            setActiveAnchorId('#')
          }

        })
      }

      window.addEventListener('scroll', () => {
        listenToScrollCb(setHeight, isScrolled, setIsScrolled)
      })
      window.addEventListener('scroll', listenToTOCElements)
      document.addEventListener('keyup', listenToKeyUp)

      return () => {
        window.removeEventListener('scroll', () => {
          listenToScrollCb(setHeight, isScrolled, setIsScrolled)
        })
        window.removeEventListener('scroll', listenToTOCElements)
        document.removeEventListener('keyup', listenToKeyUp)
      }
    }, [])
  }

  router = useRouter()
  let hashValFromUrl

  if (router) {
    const pageUrl = router.asPath
    hashValFromUrl = pageUrl.split('#')[1]
  }

  const productDetails = {
    productName: pageData?.metaData?.name,
    productVersion: router.asPath.split('/')[3],
    productId: pageData?.metaData?.id,
  }

  if (!hashValFromUrl) hashValFromUrl = ''

  let tocExists = false

  if (toc != undefined && toc[0]?.value != '' && toc[0]?.value != undefined) tocExists = true

  const usbDropdownRef = useRef()

  let breadcrumbItems: any
  const topLevelItems = buildNestedStructure(sideNavData)
  const segments = router.asPath.replace(/^\/+/, '').split('/')
  let leftRailLinksTitle: string = pageData?.metaData?.name
  const [cookiesVal] = useCookies(['locale']);
  const localeVal = cookiesVal.locale || process.env.DEFAULT_LOCALE
  let removeIndexValue: any;
  if(router.asPath.split('/').includes('products') === true && localeVal == 'en-us') {
    removeIndexValue = [4]
  } else if(router.asPath.split('/').includes('products') === true && localeVal != 'en-us') {
    removeIndexValue = [3,5]
  } else {
    removeIndexValue = [4]
  }
  if (process.env.SITE_BRAND === 'elavon') {

    const [cookies] = useCookies(['countryCode'])
    const countryCode = (cookies.countryCode || 'US')

    if (router.asPath.split('/').includes('products') === false) {
      breadcrumbItems = [
        ...GetBreadcrumbs([], false, 0, 0).map((item) => ({
          ...item,
          text: item.text.charAt(0).toUpperCase() + item.text.slice(1) || '',
        })),
      ]
    } else {
      breadcrumbItems = getElavonNonUSBreadcrumbs(countryCode, pageData?.genericSection?.heading, router.asPath.split('/'))
    }
  } else {
    breadcrumbItems = getCustomBreadcrumbs(pageData, segments)
  }

  const pageTitleMetadata: any = pageData?.metaData?.pageTitles  

  const promoteSuccessHandler = () => {
    setShowSuccessMessage(true)
  }

  const handleDropdownChange = (event: any) => {
    sandboxDropdownChangeCb(event, localeVal);
  };

  // Update documentation header design for Tablet/Mobile view

  let documentHeader = styles.headerSectionSandboxEnabled
  let headerSectionMobile: any = ''
  let defaultTocValue: any = ''

  const productNameTitle =
    process.env.SITE_BRAND === 'elavon'
      ? pageData?.metaData?.name
      : leftRailLinksTitle

  if (process.env.FEATURE_SANDBOX_PROJECTS === 'false') {
    documentHeader = styles.headerSection
    headerSectionMobile = (
      <div className={styles.headerSectionMobile}>
        <USBGrid
          gridGap={defaultGridOptions.gridGap}
          alignItems={defaultGridOptions.alignItems}
          columnCount={defaultGridOptions.columnCount}
          justifyContent={defaultGridOptions.justifyContent}
          display={defaultGridOptions.display}
          padding={defaultGridOptions.padding}
        >
          <USBColumn
            layoutOpts={tenColsOption}
            addClasses={styles.headerSectionLayout}
          >
            {process.env.SITE_BRAND === 'elavon' ? (
              <Breadcrumb
                items={breadcrumbItems}
                updateLayoutOpt={true}
              ></Breadcrumb>
            ) : (
              <Breadcrumb
                pageTitleMetadata={pageTitleMetadata}
                removeMenuFlag={true}
                removeMenuIndex={removeIndexValue}
                updateLayoutOpt={true}
                productVersionNumber={true}
              />
            )}
            <h1 className={styles.docTitle}>{pageTitle}</h1>
          </USBColumn>
        </USBGrid>
      </div>
    )
    if (toc?.length > 0) {
      defaultTocValue = toc[0].value
    }
  }

  let leftSideNavStyle: string = `${styles.leftSideViewDocuments}`

  if (!hideScrollBar) {
    leftSideNavStyle = `${styles.leftSideViewDocuments} ${styles.withScroll}`
  }

  return (
    <>
      <div>{headerSectionMobile}</div>
      <USBGrid
        gridGap={defaultGridOptions.gridGap}
        alignItems={defaultGridOptions.alignItems}
        columnCount={defaultGridOptions.columnCount}
        justifyContent={defaultGridOptions.justifyContent}
        display={defaultGridOptions.display}
        padding={defaultGridOptions.padding}
        addClasses="leftSideGrid"
      >
        <USBColumn
          layoutOpts={threeColsOption}
          addClasses={styles.leftSideColumn}
        >          
          <p className={styles.productNameTitle}>{productNameTitle}</p>
          <div
            data-testid={'leftSideBarDocuments'}
            className={leftSideNavStyle}
          >
            <SideNavMenu items={topLevelItems} />
          </div>          
        </USBColumn>
        <USBColumn
          layoutOpts={tenColsOption}
          addClasses={styles.markdownContent}
        >
          <div className={documentHeader}>
            {process.env.SITE_BRAND === 'elavon' ? (
              <Breadcrumb
                items={breadcrumbItems}
                updateLayoutOpt={true}
              ></Breadcrumb>
            ) : (
              <Breadcrumb
                pageTitleMetadata={pageTitleMetadata}
                removeMenuFlag={true}
                removeMenuIndex={removeIndexValue}
                updateLayoutOpt={true}
                productVersionNumber={true}
              />
            )}
            {showSuccessMessage && (
              <>
                <USBNotification
                  variant="confirmation"
                  id="promote_cta_success"
                  iconAssistiveText={{ label: 'Confirmation' }}
                  dataTestId="promote_cta_api-success-notification"
                  notificationData={[
                    {
                      text: "Your request has been sent and is in review. You'll receive an email within the next business day with further instructions.",
                    },
                  ]}
                />
              </>
            )}
            {/* TOC Drop down */}
            {tocExists
              ? process.env.FEATURE_SANDBOX_PROJECTS === 'true' && (
                  <USBDropdown
                    forwardedRef={usbDropdownRef}
                    dropdownType="outlined"
                    addClasses={styles.tocDropdownWrapper}
                    labelText={t('onThisPage', {translation, lng: lang})}
                    
                    defaultSelected={selectedDropDownValue}
                    dataTestId="toc-dropdown"
                    items={buildTocMenu(toc)}
                    handleChange={tocFeatureSandboxProductsDropdownCb}
                  ></USBDropdown>
                )
              : ''}
            {/* TOC Drop down */}
            <h1 className={styles.docTitle} id="doctitle-header">{pageTitle}</h1>

            {process.env.SITE_BRAND !== 'elavon' &&
            segments[1] == 'products' ? (
              <div className={styles.addProjectBtnWrapper}>
                <ProductHeroButtonWrapper
                  apiProductDetails={productDetails}
                  promoteSuccessHandler={promoteSuccessHandler}
                  documentLink={
                    sideNavData ? sideNavData[0]?.path?.split('/')[4] : ''
                  }
                  sideNavData={sideNavData}
                ></ProductHeroButtonWrapper>
                {process.env.FEATURE_SANDBOX_PROJECTS === 'true' && (
                  <div className={styles.productOverviewDropdown}>
                    {displayVersions && (
                      <USBDropdown
                        dropdownType="text"
                        labelText=""
                        listDirection="bottom"
                        items={displayVersions}
                        defaultSelected={
                          localeVal !== 'en-us' ? t('versionNumber', { translation, lng: lang }) + ' ' + router.asPath.split('/')[4] : t('versionNumber', { translation, lng: lang }) + ' ' + router.asPath.split('/')[3]
                        } 
                        dataTestId={'VersionList'}
                        handleChange={handleDropdownChange}
                      ></USBDropdown>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {/* TOC Drop down */}
          {tocExists
            ? process.env.FEATURE_SANDBOX_PROJECTS === 'false' && (
                <>
                  <p className={styles.tocHeadingMobile}>ON THIS PAGE</p>
                  <USBDropdown
                    forwardedRef={usbDropdownRef}
                    dropdownType="outlined"
                    addClasses={styles.tocDropdownWrapper}
                    labelText=""
                    defaultSelected={defaultTocValue}
                    dataTestId="toc-dropdown"
                    items={buildTocMenu(toc)}
                    handleChange={mobileTocDropdownCb}
                  ></USBDropdown>
                </>
              )
            : ''}
          {/* TOC Drop down */}
          <div
            id="markdown-content"
            className={styles.documentPageMDContent + ' markdown-block'}
            tabIndex={-1}
          >
            <ReactMarkDown content={pageData?.genericSection?.body} router={router} />
          </div>
        </USBColumn>
        {tocExists ? (
          <USBColumn
            layoutOpts={threeColsOption}
            addClasses={
              process.env.SITE_BRAND === 'elavon' 
                ? styles.tableOfContentSectionWrapper
                : styles.MDTOCSectionWrapper}
          >
            <div className={process.env.SITE_BRAND !== 'elavon' ? styles.fixedPosition : ''}>
              <TableOfContent
              items={toc}
              hashValFromUrl={hashValFromUrl}
              tocTitle={t('onThisPage', {translation, lng: lang})}
              onItemClick={handleClick}
              activeAnchor={activeAnchorId}
              maxDepth={2}
              tocTopElement={pageTitle}
              />
            </div>
          </USBColumn>
        ) : (
          ''
        )}
      </USBGrid>
    </>
  )
}

export function tocFeatureSandboxProductsDropdownCb(e: any, selectedItem: any) {
  let selectedTocTitle = selectedItem.value.replace(/\s/g, '-').toLowerCase()
  selectedTocTitle = selectedTocTitle + '_toc'
  handleClick(e, selectedTocTitle)

  return true
}

export function mobileTocDropdownCb(e: any, selectedItem: any) {
  let selectedTocTitle = selectedItem.value.replace(/\s/g, '-').toLowerCase()
  selectedTocTitle = selectedTocTitle + '_toc'
  handleClick(e, selectedTocTitle)

  return true
}

export function listenToKeyUp(event: KeyboardEvent) {
  if (event.key === 'Tab') {
    if (document.activeElement?.tagName === 'BODY') {
      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      const markdownContent: any =
        document.querySelector<HTMLElement>('#markdown-content')
      const focusableContent =
        markdownContent.querySelectorAll(focusableElements)
      const offset = 0

      for (let index = 0; index < focusableContent.length; index++) {
        const element = focusableContent[index]
        const top = element.getBoundingClientRect().top
        const isVisible =
          top + offset >= 0 && top - offset <= window.innerHeight

        if (isVisible) {
          focusableContent[index].focus()
          return
        }
      }
    }
  }

  return true
}

export function listenToScrollCb(
  setHeight: any,
  isScrolled: any,
  setIsScrolled: any
) {
  const windowHeight = window.innerHeight
  const footerHeight =
    document.querySelector<HTMLElement>('footer')?.offsetHeight || 0
  const heightToHideFrom = document.documentElement.offsetHeight - windowHeight
  const winScroll =
    (document.body.scrollTop || document.documentElement.scrollTop) + 400
  const newHeigth = windowHeight - footerHeight
  setHeight(newHeigth + 10)

  if (winScroll > heightToHideFrom) {
    isScrolled && setIsScrolled(false)
  } else {
    setIsScrolled(true)
  }

  return true
}

export default ThreeColLayout