export const validateProjectName = (input: any) => {

  const nameRules = RegExp(
    /^[a-zA-Z]([a-zA-Z0-9_ -]*[a-zA-Z0-9_ -])?$/g
  )
  if (nameRules.test(input)) {
    return [input]
  } else {
    /* Blank Field */
    if (input.replace(/\s/g, '').length < 1) {
      return [input, 'default']
    }
    /* Character more than 50 */
    if (input.replace(/\s/g, '').length > 50) {
      return [input, 224]
    }
    /* Mismatch with rules */
    return [input, 222]
  }

}

export const ValidateprojectUrl = (input: string) => {
  return [input, urlValidationFunction(input)];
}

export const urlValidationFunction = (input: string) => {
  if (input.length === 0) {
    return null;
  }

  const urlRules = RegExp(
    /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)\S+$/
  );
  if (urlRules.test(input)) {
    return null;
  } else {
    return 222;
  }
}

export const descriptionValidationFunction = (input: string) => {
  if (input.length === 0) {
    return null;
  }
  if (input.length > 1000) {
    return 221;
  }

  return null;
}