import React, { useState } from 'react'
import { Formik, Form, Field, FormikProps } from 'formik'
import { useGetUserInfoClientSide } from '@/utils/user/user.util'
import Router from 'next/router'
import * as TealiumHandlers from '@/modules/tealium/utilities/events/clickHandlers'
// Shield Components
import USBButtonGroup from '@usb-inner-src/react-button-group'
import USBButton from '@usb-shield/react-button'
import USBTextInput from '@usb-shield/react-forms-input-text'
import USBTextArea from '@usb-shield/react-forms-text-area'
import USBRadioGroup from '@usb-shield/react-forms-radio-group'
import USBSelect from '@usb-shield/react-forms-select'
import USBModal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@usb-shield/react-modal'
import '@usb-shield/react-modal/dist/library/styles/index.css'
import USBNotification from '@usb-shield/react-notification'
import {
  ModalCloseEventObject,
  ShieldFieldStatus,
  ShieldNotificationItem,
} from '@/models/shield.model'

import ReactLoadingInline from '@/components/ReactLoaders/ReactLoadingInline'
import pageContent from './addToProjectComponent.content.json'
import { ModalProps, FormValues } from './addToProjectCTA.model'
import styles from './AddToProjectCTA.module.scss'
import { useProjectsQuery } from '@/modules/credentials/services/sandbox-projects.query'
import { getProjectDropdownDataAndConsumerKey } from './addToProject.util'
import { useProjectsMutation } from '@/modules/credentials/services/sandbox-projects.mutation'
import { UpdatedSandboxProject } from '@/modules/credentials/projects/projectDetails/projectDetails.model'
import {
  validateProjectName,
  ValidateprojectUrl,
} from './addToProjectValidation.util'
import { showSuccessToast } from '@/utils/toast/toast'

const AddToProjectCTA = ({ ctaText, productDetails }: ModalProps) => {
  const { userInfo } = useGetUserInfoClientSide()

  const { addProjectMutation, addProductToProjectMutation } =
    useProjectsMutation()
  const addToProjectModalContent = pageContent.content
  const addToProjectModalFormFields = addToProjectModalContent.formFields
  const formErrorHandleData = pageContent.errors

  const productNameWithVersion = `${productDetails.productName} (${productDetails.productVersion})`

  // Get Project drop down list to use when user select "existing"
  const { useFetchProjectsQuery } =
    useProjectsQuery()
  const { isLoading, isSuccess, data: projectList } = useFetchProjectsQuery()
  const { projectDropDownData, projectsConsumerKey } =
    getProjectDropdownDataAndConsumerKey(
      productDetails.sandboxProductName,
      isSuccess,
      projectList
    )

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [notificationData, setNotificationData] = useState<
    ShieldNotificationItem[]
  >([])
  const [processing, setProcessing] = useState<boolean>(false)
  const [disableCancelClose, setDisabledCancelClose] = useState<null | boolean>(
    null
  )
  /* AddToProject button clicked - Open a Modal */
  const AddToProjectClick = () => {
    if (notificationData) {
      setNotificationData([])
    }
    setModalIsOpen(true)
    setProcessing(false)
    setDisabledCancelClose(false)
  }

  // Method to update the formik field values
  const getFieldValues = (
    props: FormikProps<FormValues>,
    status: ShieldFieldStatus,
    name: string
  ) => {
    const eventObject = {
      target: {
        name,
        value: status.inputValue,
      },
    }
    props.handleChange(eventObject)
    return [status.inputValue]
  }
  const handleCreateProjectSuccess = (newProject: UpdatedSandboxProject) => {
    const appName = newProject.projectName
    setTimeout(() => {
      Router.push('/credentials/' + appName)
    })
    setModalIsOpen(!modalIsOpen)
    showSuccessToast(`${newProject.projectName} has been created`)
  }
  const handleCreateProjectError = (apiError: any) => {
    setProcessing(false)
    setDisabledCancelClose(false)
    if (apiError.response.status == 409) {
      const notificationInfo: any = [
        { text: addToProjectModalFormFields.errorMessages.projectNameInUse },
      ]
      setNotificationData(notificationInfo)
    } else if (apiError.response.status == 422) {
      const notificationInfo: any = apiError.response.data.errors
      setNotificationData(notificationInfo)
    } else {
      const notificationInfo: any = [
        {
          text: addToProjectModalFormFields.errorMessages.apiError,
        },
      ]
      setNotificationData(notificationInfo)
    }
  }
  // Handle the form submission
  const submitForm = async (values: FormValues) => {
    if (!userInfo) {
      /* NOTE: WORK PENDING BELOW  - what to do if user is not logged in ?*/
      return
    }

    setNotificationData([])

    if (values.status == 'new') {
      setProcessing(true)
      setDisabledCancelClose(true)
      addProjectMutation.mutate(
        { userInfo, values },
        {
          onSettled(data: UpdatedSandboxProject | undefined, error: any) {
            if (error) {
              handleCreateProjectError(error)
            }
            if (data) {
              handleCreateProjectSuccess(data)
            }
          },
        }
      )
    } else {
      if (!values.projectName) {
        const notificationInfo: any = [{ text: 'Please select a project' }]
        setNotificationData(notificationInfo)
        return
      }

      const projectDetail: any = {
        projectName: values.projectName,
        credentials: {
          consumerKey: projectsConsumerKey[values.projectName],
        },
      }

      setProcessing(true)
      setDisabledCancelClose(true)

      addProductToProjectMutation.mutate(
        {
          userInfo,
          projectDetail,
          productName: values.productName,
        },
        {
          onSettled: (data: UpdatedSandboxProject | undefined, error: any) => {
            if (error) {
              handleCreateProjectError(error)
            }
            if (data) {
              const appName = projectDetail?.projectName
              Router.push('/credentials/' + appName)
              setModalIsOpen(!modalIsOpen)
              showSuccessToast(`${productDetails.productName} has been added`)
            }
          },
        }
      )
    }
  }

  return (
    <>
      <div className="modalCta">
        <USBButton
          dataTestId="addToProjectModel"
          type="button"
          ctaStyle="standard"
          emphasis="heavy"
          size="medium"
          spacing={{ margin: 0 }} // keep margin 0 - if required adjust the parent component
          addClasses={styles.addToProjectBtn}
          handleClick={() => AddToProjectClick()}
        >
          {ctaText}
        </USBButton>
      </div>

      <div className="modalBox">
        <USBModal
          isOpen={modalIsOpen}
          preventPageScroll={true}
          dataTestId="modalCloseIcon"
          handleClose={({
            clickedOutsideModal,
            escapeKeyPressed,
          }: ModalCloseEventObject) => {
            if (
              !clickedOutsideModal &&
              !escapeKeyPressed &&
              !disableCancelClose
            ) {
              setModalIsOpen(false)
            }
          }}
        >
          <ModalHeader id="add-to-project-mh">
            {addToProjectModalContent.modalTitle}
          </ModalHeader>
          {/*  ProductOverview Component pageData.sandboxProductName = 'Check-Payables-2-0-0' */}
          <Formik
            initialValues={{
              productName: productDetails.sandboxProductName,
              status: '',
              projectName: '',
              description: '',
              url: '',
            }}
            onSubmit={submitForm}
          >
            {(props: FormikProps<FormValues>) => {
              return (
                <>
                  <section className={styles.addToProjectForm}>
                    <Form name="addToProjectForm">
                      <ModalBody>
                        {/* show erros */}
                        {notificationData?.length > 0 && (
                          <USBNotification
                            variant="error"
                            iconAssistiveText={{ label: 'Error' }}
                            notificationData={notificationData}
                            dataTestId="errorNotification"
                          />
                        )}
                        <div>
                          <div className={styles.modelDescription}>
                            <p>{addToProjectModalContent.modalDescription}</p>
                          </div>
                          <div className={styles.formInput}>
                            <p className={styles.formLabel}>
                              {addToProjectModalFormFields.productLabel}
                            </p>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: productNameWithVersion,
                                }}
                              ></div>
                            </p>
                          </div>
                          <div className={styles.formInput}>
                            <Field
                              type="radio"
                              name="status"
                              inputName="status"
                              legendText={
                                addToProjectModalFormFields.projectLabel
                              }
                              component={USBRadioGroup}
                              dataTestId="status"
                              isOptional={false}
                              addClasses={styles.statusRadiobtn}
                              options={[
                                { value: 'new', label: 'New' },
                                { value: 'existing', label: 'Existing' },
                              ]}
                              statusUpdateCallback={(
                                status: ShieldFieldStatus
                              ) => {
                                props.values.projectName = ''
                                status.inputValue === 'existing' &&
                                projectDropDownData &&
                                projectDropDownData.length == 0
                                  ? setProcessing(true)
                                  : setProcessing(false)

                                getFieldValues(props, status, 'status')
                              }}
                            ></Field>
                          </div>
                          {props.values.status === 'new' && (
                            <div className={styles.formInput}>
                              <Field
                                type="text"
                                name="projectName"
                                dataTestId="projectName"
                                inputName="projectName"
                                labelText={
                                  addToProjectModalFormFields.projectNameLabel
                                }
                                component={USBTextInput}
                                errorMessages={
                                  formErrorHandleData.projectNameErrorMessages
                                }
                                filteringFunction={validateProjectName}
                                callbackFrequency={'every'}
                                statusUpdateCallback={(
                                  status: ShieldFieldStatus
                                ) => {
                                  getFieldValues(props, status, 'projectName')
                                }}
                              />
                            </div>
                          )}

                          {isLoading && props.values.status === 'existing' && (
                            <ReactLoadingInline />
                          )}

                          {props.values.status === 'existing' &&
                            isSuccess &&
                            projectDropDownData &&
                            projectDropDownData.length > 0 && (
                              <div className={styles.formInput}>
                                <Field
                                  as={USBSelect}
                                  name="existingProjectName"
                                  dataTestId="existingProjectName"
                                  inputName="existingProjectName"
                                  callbackFrequency="every"
                                  optionPlaceholderText={
                                    addToProjectModalFormFields.ProjectNameDropdownPlaceHolderText
                                  }
                                  optionsArray={projectDropDownData}
                                  errorMessages={{
                                    224: addToProjectModalFormFields
                                      .errorMessages.projectNameDropdown,
                                  }}
                                  statusUpdateCallback={(
                                    status: ShieldFieldStatus
                                  ) => {
                                    getFieldValues(props, status, 'projectName')
                                  }}
                                />
                              </div>
                            )}
                          {props.values.status === 'existing' &&
                            projectDropDownData &&
                            projectDropDownData.length == 0 && (
                              <div className={styles.formInput}>
                                {
                                  addToProjectModalFormFields.noProjectFoundMessage
                                }
                              </div>
                            )}
                          {props.values.status === 'new' && (
                            <div className={styles.formInput}>
                              <Field
                                type="text-area"
                                name="description"
                                inputName="description"
                                labelText={
                                  addToProjectModalFormFields.descriptionLabel
                                }
                                component={USBTextArea}
                                maxlength="1000"
                                isOptional={true}
                                dataTestId="descriptionArea"
                                statusUpdateCallback={(
                                  status: ShieldFieldStatus
                                ) => {
                                  getFieldValues(props, status, 'description')
                                }}
                                errorMessages={
                                  formErrorHandleData.descriptionErrorMessages
                                }
                                validate={(): void => {/* do nothing */}}
                                validationFunction={(): void => {/* do nothing */}}
                              />
                            </div>
                          )}
                          {props.values.status === 'new' && (
                            <div className={styles.formInput}>
                              <Field
                                type="text"
                                name="url"
                                inputName="url"
                                labelText={addToProjectModalFormFields.urlLabel}
                                component={USBTextInput}
                                isOptional={true}
                                dataTestId="urlArea"
                                callbackFrequency={'every'}
                                statusUpdateCallback={(
                                  status: ShieldFieldStatus
                                ) => {
                                  getFieldValues(props, status, 'url')
                                }}
                                filteringFunction={ValidateprojectUrl}
                                errorMessages={
                                  formErrorHandleData.urlErrorMessages
                                }
                              />
                            </div>
                          )}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <USBButtonGroup addClasses={'modal-button-group'}>
                          <USBButton
                            ctaStyle="standard"
                            emphasis="heavy"
                            size="medium"
                            type="submit"
                            id="createProjectSubmit"
                            aria-describedby="submit form"
                            addClasses="usb-fleet-continue"
                            disabled={
                              processing ||
                              (!props.values.status ? true : processing)
                            }
                            handleClick={TealiumHandlers.addToProjectHandler}
                          >
                            <div data-testid="modalSaveButton">
                              {props.values.status === 'existing' &&
                                projectDropDownData &&
                                projectDropDownData.length > 0 &&
                                processing && <ReactLoadingInline />}
                              {props.values.status === 'new' && processing && (
                                <ReactLoadingInline />
                              )}
                              {addToProjectModalFormFields.buttonLabels.save}
                            </div>
                          </USBButton>
                          <USBButton
                            id="secondary-modal-button"
                            ctaStyle="standard"
                            emphasis="subtle"
                            disabled={disableCancelClose}
                            handleClick={() => setModalIsOpen(false)}
                          >
                            <div data-testid="modalCancelButton">
                              {addToProjectModalFormFields.buttonLabels.cancel}
                            </div>
                          </USBButton>
                        </USBButtonGroup>
                      </ModalFooter>
                    </Form>
                  </section>
                </>
              )
            }}
          </Formik>
        </USBModal>
      </div>
    </>
  )
}

export default AddToProjectCTA
