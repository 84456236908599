import '@usb-shield/react-link/dist/library/styles/index.css'
import '@usb-shield/react-grid/dist/library/styles/index.css'
import 'highlight.js/styles/default.css'
import ThreeColLayout from './DisplayViews/ThreeColLayout'
import ThreeColRedocLayout from './DisplayViews/ThreeColRedocLayout'
import { displayVersionListItems } from '@/utils/product-versions.utils'
import ThreeColSectionsLayout from './DisplayViews/ThreeColSectionsLayout'

//
const ProductDocumentPage = ({
  pageData,
  sideNavData,
  toc,
  productName,
  specData,
  prodVersionsData
}: {
  pageData: any
  sideNavData: any
  toc: any
  productName: any
  specData: any,
  prodVersionsData: any
}) => {
  let renderDisplayView: any = ''
  let viewData: any = ''
  let displayView = ''
  const displayVersions: any = displayVersionListItems(prodVersionsData, pageData?.metaData?.id, '')

  displayView = 'ThreeColLayout'
  viewData = {
    pageData,
    sideNavData,
    toc,
    productName,
    displayVersions
  }

  if (pageData?.documentationSectionsCFM) {
    
    displayView = 'ThreeColSectionsLayout';
    viewData = {
      pageData,
      sideNavData,
      productName,
      displayVersions
    }
  }

  if (specData?.openapi) {
    displayView = 'ThreeColRedocLayout'
    viewData = {
      pageData,
      specData,
      sideNavData,
      productName,
    }
  }

  switch (displayView) {

    case 'ThreeColRedocLayout':
      renderDisplayView = (
        <ThreeColRedocLayout {...viewData}></ThreeColRedocLayout>
      )
      break

    case 'ThreeColSectionsLayout':

      renderDisplayView = (
        <ThreeColSectionsLayout {...viewData}></ThreeColSectionsLayout>
      )
      break;

    default:
      // parameter updates to accomodate ThreeColLayout.tsx
      viewData.componentToc = toc
      viewData.toc = undefined

      renderDisplayView = (
        <ThreeColLayout {...viewData}></ThreeColLayout>
      )
        break;
  }

  return <>{renderDisplayView}</>
}

export default ProductDocumentPage
