import { NavItemProps } from '@/modules/products/ProductDocumentPage/components/SideNavMenu/SideNavMenu.model'

export const findItemIdByPath = (
  items: NavItemProps<string>[],
  searchPath: string
): string | undefined => {
  const index = searchPath.indexOf("#");
  if (index >= 0)
    searchPath = searchPath.substring(0, index);
  const lastIndexOf = searchPath.lastIndexOf('/')
  const pathEndsInV1 = searchPath.substring(lastIndexOf + 1)

  for (const item of items) {
    if (item.path === searchPath || pathEndsInV1 === 'v1') {
      return item.id
    } else if (item.subNav && item.subNav.length > 0) {
      const itemId: string | undefined = findItemIdByPath(
        item.subNav,
        searchPath
      )

      if (itemId) {
        return itemId
      }
    }
  }
}
