import "@usb-shield/react-grid/dist/library/styles/index.css";
import ProductDocumentPage from "@/modules/products/ProductDocumentPage/ProductDocumentPage";
import ProductOverviewPage from "@/modules/products/ProductOverviewPage/ProductOverviewPage";
import { UserOrganization } from '@/models/user.model';
import { useRouter } from 'next/router';
import Custom404 from "@/modules/custom404/Custom404";

const ProductTemplateSelectorPage = ({
  pageData,
  sideNavData,
  toc,
  productName,
  specData,
  prodVersionsData,
  notFound,
  visibilityLevel,
  userSessionData,
}: {
  pageData: any
  sideNavData: any
  toc: any
  productName: any
  specData: any
  prodVersionsData: any
  notFound: any,
  visibilityLevel: any
  userSessionData: any
}) => {

  const verifiedOrg: UserOrganization | null | undefined = userSessionData?.organization

  const router = useRouter()
  const path = router.asPath
  const segments = path.replace(/^\/+/,'').split('/')
  
  if (notFound) { // if notFound, return 404 immediately
    return <Custom404 />
  }

  if (segments && segments[0] && segments[0] == 'products'){
    // this is a product overview or product documentation page
    if (
      userSessionData &&
      visibilityLevel === 'restricted' &&
      !verifiedOrg &&
      segments?.length > 3
    ) {
      const pageUrl = '/products/' + pageData?.metaData?.id + '/' + segments[2]
      // redirect to product overview page and set query params
      router.push({
        pathname: pageUrl,
        query: { cb: "ra", ref: `${window.location.origin.toString()}` + router.asPath },
      })
      return (<></>)

    } else if (pageData?.edpProduct?.name){ //this page uses the product overview template
      return (<ProductOverviewPage pageData={pageData} sideNavData={sideNavData} prodVersionsData={prodVersionsData} visibilityLevel={visibilityLevel}></ProductOverviewPage>)
    } else {
      return (<ProductDocumentPage productName={productName} toc={toc} pageData={pageData} sideNavData={sideNavData} specData={specData} prodVersionsData={prodVersionsData}></ProductDocumentPage>)
    }
  } else {
    return (<ProductDocumentPage productName={productName} toc={toc} pageData={pageData} sideNavData={sideNavData} specData={specData} prodVersionsData={prodVersionsData}></ProductDocumentPage>);
  }
};

export default ProductTemplateSelectorPage