import { GenericToc } from "@/utils/toc/toc.model";

const capitalizeWordsWithDash = (input: string): string => {
    return input
        .split('-') // Split the string by the dash
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
        .join('-');
};
const getElavonNonUSBreadcrumbs = (countryCode: string, heading: string, path: any): {
    id: number
    text: string
    href: string
}[] => {
    let intermediateBreadcrumbs: any = []

    let breadCrumbText = path.hasOwnProperty(2)
      ? capitalizeWordsWithDash(path[2])
      : capitalizeWordsWithDash(path[1])

    let breadCrumbHref = '/products' + '/' + path[2] + '/' + path[3]

    if (countryCode !== 'US') {
      const removeValFromIndex = [0, 1, 2]
      const countryCodeFromUrl = path[2]

      for (let i = removeValFromIndex.length -1; i >= 0; i--) {
        path.splice(removeValFromIndex[i],1);
      }
      
      breadCrumbText = capitalizeWordsWithDash(path[0])
      breadCrumbHref = '/products/' + countryCodeFromUrl + '/' + path[0] + '/' + path[1]
    }
    
    return [
      {
        id: 2,
        text: breadCrumbText,
        href: breadCrumbHref,
        handleClick: () => {
          localStorage.removeItem('activeItem')
        },
      },
      ...intermediateBreadcrumbs,
      {
        id: intermediateBreadcrumbs.length + 3,
        text: heading,
        href: '',
      },
    ]
}

const buildTocMenu: any = (
    toc: GenericToc<string>[],
    includeChildren = false
) => {
    let children: GenericToc<string>[] = []
    const flattenToc = toc.map((item: GenericToc<string>) => {
      if (includeChildren && item.children && item.children.length) {
        children = [...children, ...item.children]
      }
      return item
    })

    return flattenToc.concat(
      children.length ? buildTocMenu(children) : children
    )
}

const getCustomBreadcrumbs = (pageData: any, segments: any) => {
    if (segments[0] == 'products') {
      segments.unshift('home')
      const links = segments.map((item: any, index: any, arr: any) => {
        if (index == 1) {
          return {
            id: 2,
            text: 'API products',
            href: '/products',
          }
        }
        if (index == 2 && arr.length == 4) {
          //product overview page
          return {
            id: 3,
            text: pageData?.metaData?.pageTitle,
            href:
              '/products' +
              '/' +
              pageData?.metaData?.pageTitle +
              '/' +
              arr[3],
          }
        }
        if (index == 2 && arr.length == 5) {
          //product overview when there is a documentation child
          return {
            id: 3,
            text: pageData?.metaData?.name,
            href: '/products' + '/' + arr[2] + '/' + arr[3],
          }
        }
        if (index == 4 && arr.length == 5) {
          // documentation page
          return {
            id: 4,
            text: pageData?.metaData?.pageTitle,
            href: '/products' + '/' + arr[2] + '/' + arr[3] + '/' + arr[4],
          }
        }
      })

      // return links
      return links.filter((link: any) => link)
    } else {
      const links = segments.map((item: any, index: any, arr: any) => {
        if (arr.length == 1) {
          //this is a root level page
          return [
            {
              id: 2,
              text: pageData?.metaData?.pageTitle,
              href: '/' + arr[0],
            },
          ]
        }
        if (arr.length == 2) {
          //this is a child of a root level page
          return [
            {
              id: 2,
              text: 'Mutual Transport Layer Security (mTLS) Guide',
              href: '/' + arr[0],
            },
            {
              id: 3,
              text: pageData?.metaData?.pageTitle,
              href: '/' + arr[0] + '/' + arr[1],
            },
          ]
        }
      })

      return links.flat()
    }
  }

export { capitalizeWordsWithDash, getElavonNonUSBreadcrumbs, buildTocMenu, getCustomBreadcrumbs }