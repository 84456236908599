import '@usb-shield/react-link/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import { defaultGridOptions } from '@/utils/usb-grid.util'
import styles from '@/modules/products/ProductDocumentPage/DisplayViews/displayViews.module.scss'
import '@usb-shield/react-grid/dist/library/styles/index.css'
import { useRouter } from 'next/router'
import USBBreadcrumb from '@usb-shield/react-breadcrumb'
import USBDropdown from '@usb-shield/react-dropdown'
import { useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import ProductHeroButtonWrapper from '@/modules/products/components/ProductHeroButtonWrapper/ProductHeroButtonWrapper'
import USBNotification from '@usb-shield/react-notification'
import { buildNestedStructure } from '@/utils/build-nested-list/build-nested-elements.util'
import SideNavMenu from '@/modules/products/ProductDocumentPage/components/SideNavMenu/SideNavMenu'
import ProductSections from '../components/ProductSections/ProductSections'
import { sandboxDropdownChangeCb } from '@/modules/products/ProductDocumentPage/utils/callbacks'
import { useCookies } from 'react-cookie'
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb'
import translation from '@/src/i18n'
import { useTranslation } from 'react-i18next';
import { getCookie } from 'cookies-next';

const TableOfContent = dynamic(
    () => import('@/components/TableOfContent/TableOfContent'),
    {
      ssr: false,
    }
  ),
  threeColsOption = {
    span: null,
    spans: {
      xlarge: 3,
      large: 3,
      medium: 3,
      small: 3,
    },
    offset: {
      xlarge: 0,
      large: 0,
      medium: 0,
      small: 0,
    },
    padding: 'normal',
    align: 'start',
    justify: 'start',
  },
  tenColsOption = {
    span: null,
    spans: {
      xlarge: 10,
      large: 10,
      medium: 8,
      small: 4,
    },
    offset: {
      xlarge: 0,
      large: 0,
      medium: 0,
      small: 0,
    },
    display: 'flex',
    padding: 'normal',
    align: 'start',
    justify: 'start',
  }

let router: any

const ThreeColSectionsLayout = ({
  pageData,
  sideNavData,
  displayVersions,
}: {
  pageData: any
  sideNavData: any
  displayVersions: any
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  router = useRouter()
  const [cookies] = useCookies(['locale'])
  const productDetails = {
    productName: pageData?.metaData?.name,
    productVersion: router.asPath.split('/')[3],
    productId: pageData?.metaData?.id,
    sideNavData: sideNavData,
  }
  let locale = getCookie('locale') ?? process.env.DEFAULT_LOCALE
  const lang = locale?.substring(0, 2)
  const { t, i18n } = useTranslation();
  let productLink = ''
  if(router.asPath.split('/').length === 6) {
    productLink =
    '/products' +
    '/' +
    router.asPath.split('/')[2] +
    '/' +
    router.asPath.split('/')[3] + '/' +
    router.asPath.split('/')[4]
  } else {
    productLink =
    '/products' +
    '/' +
    router.asPath.split('/')[2] +
    '/' +
    router.asPath.split('/')[3]
  }

  const breadcrumbItems = [
    { id: 2, text: 'API products', href: '/products' },
    {
      id: 3,
      text: pageData?.metaData?.name || '',
      href: productLink,
    },
    { id: 4, text: pageData?.metaData?.pageTitle, href: '' },
  ]
  const promoteSuccessHandler = () => {
    setShowSuccessMessage(true)
  }

  const handleDropdownChange = (event: any) => {
    const localVal = cookies.locale
    sandboxDropdownChangeCb(event, localVal);
  };

  const usbDropdownRef: any = useRef()
  const usbDropdownRef1: any = useRef()
  const [itemList, setItemList] = useState([{ id: 0, value: '' }])

  // Update documentation header design for Tablet/Mobile view
  let documentHeader = ''
  let headerSectionMobile: any = ''
  if (process.env.FEATURE_SANDBOX_PROJECTS === 'false') {    
    documentHeader = styles.headerSection
    headerSectionMobile = (
      <div className={styles.headerSectionMobile}>
        <USBGrid
          gridGap={defaultGridOptions.gridGap}
          alignItems={defaultGridOptions.alignItems}
          columnCount={defaultGridOptions.columnCount}
          justifyContent={defaultGridOptions.justifyContent}
          display={defaultGridOptions.display}
          padding={defaultGridOptions.padding}
        >
          <USBColumn
            layoutOpts={tenColsOption}
            addClasses={styles.headerSectionLayout}
          >
            <USBBreadcrumb breadcrumbs={breadcrumbItems}></USBBreadcrumb>
            <h1 className={styles.docTitle}>{pageData?.metaData?.pageTitle}</h1>
          </USBColumn>
        </USBGrid>
      </div>
    )
  }

  return (
    <>
      {!process.env.FEATURE_SANDBOX_PROJECTS && <div>{headerSectionMobile}</div>}
      <USBGrid
        gridGap={defaultGridOptions.gridGap}
        alignItems={defaultGridOptions.alignItems}
        columnCount={defaultGridOptions.columnCount}
        justifyContent={defaultGridOptions.justifyContent}
        display={defaultGridOptions.display}
        padding={defaultGridOptions.padding}
      >
        <USBColumn
          layoutOpts={threeColsOption}
          addClasses={styles.leftSideColumn}
        >
          <div>
            <p className={styles.productNameTitle}>{pageData?.metaData?.name}</p>
            <div
              data-testid={'leftSideBarDocuments'}
              className={`${styles.leftSideViewDocuments} ${styles.withScroll}`}
            >
              <SideNavMenu items={buildNestedStructure(sideNavData)} />
            </div>
          </div>
        </USBColumn>
        <USBColumn
          layoutOpts={tenColsOption}
          addClasses={styles.markdownContent}
        >
          <div className={documentHeader}>
            <Breadcrumb
              items={breadcrumbItems}
              updateLayoutOpt={true}
            ></Breadcrumb>
            {showSuccessMessage && (
              <>
                <USBNotification
                  variant="confirmation"
                  id="promote_cta_success"
                  iconAssistiveText={{ label: 'Confirmation' }}
                  dataTestId="promote_cta_api-success-notification"
                  notificationData={[
                    {
                      text: "Your request has been sent and is in review. You'll receive an email within the next business day with further instructions.",
                    },
                  ]}
                />
              </>
            )}
            {/* TOC Drop down */}
            {process.env.FEATURE_SANDBOX_PROJECTS === 'true' && (
              <USBDropdown
                forwardedRef={usbDropdownRef1}
                dropdownType="outlined"
                addClasses={styles.tocDropdownWrapper}
                labelText={t('onThisPage', {translation, lng: lang})}
                defaultSelected={''}
                dataTestId="toc-dropdown"
                items={itemList}
                handleChange={(e: any, selectedItem: any) => {
                  if (router) {
                    router.push(`#${selectedItem.id}-toc`)
                  }
                }}
              ></USBDropdown>
            )}
            {/* TOC Drop down */}
            <h1 className={styles.docTitle}>{pageData.metaData.pageTitle}</h1>
            <div className={styles.addProjectBtnWrapper}>
              <ProductHeroButtonWrapper
                apiProductDetails={productDetails}
                promoteSuccessHandler={promoteSuccessHandler}
                documentLink={sideNavData[0]?.path?.split('/')[4]}
                sideNavData={sideNavData}
              ></ProductHeroButtonWrapper>

              {process.env.FEATURE_SANDBOX_PROJECTS === 'true' && (
                <div className={styles.productOverviewDropdown}>
                  {displayVersions && (
                    <USBDropdown
                      dropdownType="text"
                      labelText=""
                      listDirection="bottom"
                      items={displayVersions}
                      defaultSelected={
                        locale !== 'en-us' ? t('versionNumber', { translation, lng: lang }) + ' ' + router.asPath.split('/')[4] : t('versionNumber', { translation, lng: lang }) + ' ' + router.asPath.split('/')[3]
                      } 
                      dataTestId={'VersionList'}
                      handleChange={handleDropdownChange}
                    ></USBDropdown>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* TOC Drop down */}
          {process.env.FEATURE_SANDBOX_PROJECTS === 'false' && (
            <>
              <p className={styles.tocHeadingMobile}>{t('onThisPage', {translation, lng: lang})}</p>
              <USBDropdown
                forwardedRef={usbDropdownRef}
                dropdownType="outlined"
                addClasses={styles.tocDropdownWrapper}
                labelText={''}
                defaultSelected={itemList[0]?.value || ''}
                dataTestId="toc-dropdown"
                items={itemList}
                handleChange={(e: any, selectedItem: any) => {
                  if (router) {
                    router.push(`#${selectedItem.id}-toc`)
                  }
                }}
              ></USBDropdown>
            </>
          )}
          {/* TOC Drop down */}

          {/* Main Content */}
          <div
            className={`productContent ${styles.markdownContentProductSections}`}
          >
            <ProductSections pageData={pageData} gridOption="TenCol" />
          </div>
        </USBColumn>
        {/* TOC column */}
        <USBColumn
          layoutOpts={threeColsOption}
          addClasses={styles.MDTOCSectionWrapper}
        >
          <div className={styles.MDTOCSectionContent}>
            <p id="TOCSectionTitle" className={styles.TOCSectionTitle}>
            {t('onThisPage', {translation, lng: lang})}
            </p>
            <TableOfContent
              selector={'.productContent'}
              setItemList={setItemList}
            />
          </div>
        </USBColumn>
      </USBGrid>
    </>
  )
}

export default ThreeColSectionsLayout
