import React, { useEffect, useState } from 'react'
import NavItem from './NavItem/NavItem'
import { NavItemProps } from './SideNavMenu.model'
import { findItemIdByPath } from '@/utils/find-item-id-by-path'
import styles from '@/modules/products/ProductDocumentPage/components/SideNavMenu/sideNavMenu.module.scss'
import { useRouter } from 'next/router'

const SideNavMenu = ({ 
  items, 
  brandDivCssClass,
  authWidgetConfigData = null
}: { 
  items: NavItemProps<string>[] 
  brandDivCssClass? : string
  authWidgetConfigData?: any
}) => {
  const router = useRouter()
  const itemId: string | undefined = findItemIdByPath(items, router.asPath)
  let activeItemInitialValue = itemId
  const [activeItem, setActiveItem] = useState(activeItemInitialValue)

  useEffect(() => {
    if (itemId !== null) {
      setActiveItem(itemId)
      activeItemInitialValue = itemId
    }
  }, [itemId])

  const handleClick = (item: NavItemProps<string>) => {
    if (activeItem?.startsWith(item.id)) {
      const parent = item.id.slice(0, -2)
      setActiveItem(parent)
      localStorage.setItem('activeItem', parent)
    } else {
      setActiveItem(item.id)
      localStorage.setItem('activeItem', item.id)
    }
  }

  const getDivCssClass = () => {
    if(brandDivCssClass){
      return brandDivCssClass === 'usb-dashboard' 
        ? `${styles.outerDiv} ${styles.usbDashboardDivCssClass}` 
        : `${styles.outerDiv} ${styles.elavonDashboardDivCssClass}`
    } else {
      return `${styles.outerDiv}`
    }
  }

  return (
    <>
      {items?.length > 0 && (
        <nav
          role="navigation"
          aria-label="side-navigation"
          className={styles.sideNavigationPanel}
        >
          <ul className={styles.sideNavigationPanelSelect} role="list">
            {items.map((item: NavItemProps<string>) => {
              return (
                <NavItem
                  key={`${item.path}-${item.title}`}
                  item={item}
                  onItemClick={handleClick}
                  isShown={activeItem}
                  liCssClass={styles['outerLi-1']}
                  divCssClass={getDivCssClass()}
                  authWidgetConfigData={authWidgetConfigData}
                />
              )
            })}
          </ul>
        </nav>
      )}
    </>
  )
}

export default SideNavMenu
