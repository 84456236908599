const SCROLL_OFFSET = -124

/**
 * Scrolls to the specified element by its ID when the DOM is mutated.
 *
 * This function sets up a MutationObserver to watch for changes in the DOM.
 * When a mutation occurs, it attempts to scroll the element with the given ID into view.
 *
 * @param elementId - The ID of the element to scroll to.
 */
export const scrollToElement = (elementId: string) => {
  const mutationObserver = new MutationObserver(() => {
    let element = window.location.hash.split('#')[1]
    scrollIntoView(element)
  })

  if (elementId && elementId.length) {
    mutationObserver.observe(document, { childList: true, subtree: true })
  }
}

/**
 * Scrolls the next sibling element of the specified element into view.
 *
 * @param elementId - The ID of the element whose next sibling should be scrolled into view.
 *
 * @remarks
 * This function finds the element by its ID, then scrolls its next sibling element into view.
 * After scrolling the element into view, it adjusts the window scroll position by a predefined offset.
 *
 * @example
 * ```typescript
 * scrollIntoView('myElementId');
 * ```
 */
export const scrollIntoView = (elementId: string) => {
  const targetElement = document.getElementById(elementId)?.nextElementSibling

  targetElement?.scrollIntoView()

  window.scrollBy(0, SCROLL_OFFSET)
}
