import { useCookies } from 'react-cookie';

const IS_ELAVON: boolean = process.env.CONTENT_BRAND === 'elavon';

const displayVersionListItems = (data: any, id: string, name: string): any => {
    const [cookies] = useCookies(['locale']);
    const locale = cookies.locale || process.env.DEFAULT_LOCALE;
    const lang = locale;
    if (!IS_ELAVON && (data != undefined) && Object.keys(data)?.length && data[process.env.CONTENT_BRAND]?.products[id]) {
        const displayVersionListLang = data[process.env.CONTENT_BRAND]?.products[id]?.displayVersionList[lang];
        const displayVersionListUs = data[process.env.CONTENT_BRAND]?.products[id]?.displayVersionList['us'];
        let displayVersionList: any = displayVersionListLang ? Object.values(displayVersionListLang) : (displayVersionListUs ? Object.values(displayVersionListUs) : []);
        let displayVersionListItems: any = [];

        displayVersionList.length > 0 && displayVersionList.map((item: any) => {
            Object.keys(item)?.map((val: any, key: any) => {
                displayVersionListItems.push(
                    {
                        id: key + 1,
                        value: name ? name + '  ' + val : 'Version: ' + val,
                        isSelectable: true,
                    }
                );
            });
            return displayVersionListItems;
        });
        return displayVersionListItems;
    }

    return false;
};

const getProductVisibilityLevel = (prodVersionData: any, prodId: string, lang: string, version: string, sessionData: any): [string, any] => {
    const filteredVersionList = prodVersionData[process.env.CONTENT_BRAND]?.products[prodId]?.displayVersionList[lang]?.filter((item: any) => version in item) || [];
    const visibilityLevel = filteredVersionList[0] && filteredVersionList[0][version]?.visibilityLevel || 'public';
    const restrictedPrivateProducts = sessionData?.restrictedPrivateProducts || [];
    let isProductRestrictedPrivate: any = false;
    restrictedPrivateProducts?.length > 0 && restrictedPrivateProducts.forEach((value: any) => {
        if (value[prodId] && value[prodId][lang]) {
            isProductRestrictedPrivate = value[prodId][lang].filter((item: any) => item === version);
        }
    });
    return [visibilityLevel, isProductRestrictedPrivate];
};

export { displayVersionListItems, getProductVisibilityLevel };
