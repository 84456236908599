import '@usb-shield/react-grid/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import {
  defaultColLayoutOptions,
  defaultGridOptions,
} from '@/utils/usb-grid.util'
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb'
import { BreadcrumbItem } from '@/components/Breadcrumb/breadcrumb.model'
import ProductHeroButtonWrapper from '../components/ProductHeroButtonWrapper/ProductHeroButtonWrapper'
import { ProductOverviewPageData } from '@/modules/products/ProductOverviewPage/productOverviewPage.model'
import ProductOverviewRightRailLinks from './components/ProductOverviewRightRailLinks'
import PromoteCTA from '@/modules/credentials/projects/components/PromoteCTA/PromoteCTA'
import USBDropdown from '@usb-shield/react-dropdown'
import USBNotification from '@usb-shield/react-notification'
import dynamic from 'next/dynamic'
import styles from './productOverviewPage.module.scss'
import { useRouter } from 'next/router'
import { ReactNode, useState } from 'react'
import { displayVersionListItems } from '@/utils/product-versions.utils'
import { useProductsQuery } from '@/modules/credentials/services/sandbox-projects.query'
import { useCookies } from 'react-cookie'
import translation from '@/src/i18n'
import { useTranslation } from 'react-i18next';
import { getCookie } from 'cookies-next';

let imageSrc = process.env.CONTENT_DOMAIN

const ReactMarkDown = dynamic(
  () => import('@/components/ReactMarkDown/ReactMarkDown'),
  {
    ssr: false,
  }
)

let heroTitleBlock: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
}

let heroImageBlock: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
}
if (process.env.CONTENT_BRAND === 'elan') {
  heroTitleBlock = {
    ...heroTitleBlock,
    spans: {
      xlarge: 6,
      large: 6,
      medium: 8,
      small: 4,
    },
  }
  
  heroImageBlock = {
    ...heroImageBlock,
    spans: {
      xlarge: 6,
      large: 6,
      medium: 8,
      small: 4,
    }
  }
}

const contentContainer: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
}

const contentColumn: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  padding: 'zero',
}

const rightSidebarColumn: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
}

const twelveColsOption = {
  span: null,
  spans: {
    xlarge: 12,
    large: 12,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'block',
  padding: 'zero',
  align: 'start',
  justify: 'stretch',
}

const ProductOverviewPage = ({
  pageData,
  sideNavData,
  prodVersionsData,
  visibilityLevel
}: {
  pageData: ProductOverviewPageData
  sideNavData: [
    {
      path: string
      title: string
      metaData: {
        displayOnOverview: string
      }
    }
  ]
  prodVersionsData: any
  visibilityLevel: string
}) => {
  const [cookiesVal] = useCookies(['locale']);
  const localeVal = cookiesVal.locale || process.env.DEFAULT_LOCALE
  const documentationBtnFlag = sideNavData && sideNavData.length > 0; 
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const displayVersions: any = displayVersionListItems(prodVersionsData, pageData?.metaData?.id, pageData?.metaData?.name)
  let locale = getCookie('locale') ?? process.env.DEFAULT_LOCALE
  locale = locale?.substring(0, 2)
  const { t, i18n } = useTranslation();


  const content1 = pageData?.edpProduct?.topOverviewSection.map(function (
    value: {
      header: {
        name: string
      }
      body: string
    },
    index: number
  ) {
    return (
      <div key={index} data-testid="productBodyContent1">
        <h2>{value.header.name}</h2>
        <ReactMarkDown content={value.body} />
      </div>
    )
  })

  const content2 = pageData?.edpProduct?.bottomOverviewSection.map(function (
    value: {
      header: {
        name: string
      }
      body: string
    },
    index: number
  ) {
    return (
      <div key={index} data-testid="productBodyContent2">
        <h2>{value.header.name}</h2>
        <ReactMarkDown content={value.body} />
      </div>
    )
  })

  const router = useRouter()
  const version = localeVal !== 'en-us' ? router.asPath.split('/')[4] : router.asPath.split('/')[3]
  const versionFromUrl = version?.split('?')
  const defaultVersion = versionFromUrl?.length > 0 ? versionFromUrl[0] : version

  const breadcrumbItems: BreadcrumbItem[] = [
    { id: 2, text: 'API products', href: '/products' },
    { id: 3, text: pageData?.edpProduct?.name || '', href: '' },
  ]

  const productDetails = {
    productName: pageData?.edpProduct?.name,
    productVersion: pageData?.edpProduct?.versionNumber,
    productId: pageData?.edpProduct?.id,
  }

  const promoteSuccessHandler = () => {
    setShowSuccessMessage(true)
  }

  // Display right rail links
  let rightRailDocumentationLinks = <></>
  let addToProjectBtnFlag = false
  
  const { useSandboxEnabledProductsQuery } = useProductsQuery()
  const {
    isLoading: isProductsLoading,
    error: productsError,
    data: productsList,
  } = useSandboxEnabledProductsQuery()

  if ( pageData && productsList) {
    const matchFound = productsList.find((product: any) => product.slug === pageData.metaData.id)
    if(matchFound) {
      addToProjectBtnFlag = true;
    }
  }

  const productDocuments: ReactNode = sideNavData?.map(
    (
      value: {
        metaData: {
          displayOnOverview: string
        }
      },
      index: number
    ) => {
      rightRailDocumentationLinks = (
        <ProductOverviewRightRailLinks
          apiProductDetails={productDetails}
          productValue={value}
          promoteSuccessHandlerCb={promoteSuccessHandler}
          visibilityLevel={visibilityLevel}
        ></ProductOverviewRightRailLinks>
      )
      // Display only if displayOnOverview="true"
      if (
        value.metaData.displayOnOverview &&
        value.metaData.displayOnOverview == 'true'
      ) {
        return (
          <div
            className={styles.eachSideBarLink}
            key={index}
            data-testid="productDocuments"
          >
            {rightRailDocumentationLinks}
          </div>
        )
      }
    }
  )

  let rightSideBarDocumentLabel

  if (productDocuments != '') {
    rightSideBarDocumentLabel = t('productDocuments', {translation, lng: locale}) 
  }

  let documentationLink = ''
  sideNavData?.map((value: { title: string; path: string }) => {
    value.title == 'getting-started' ? (documentationLink = value.path) : ''
  })

  // Auto load promote modal on resctricted documentation redirect
  let promoteCTAAutoOpen = <></>
  if (router.query?.cb === 'ra' && router.query?.ref) {
    const documentLink = router.query?.ref

    if (documentLink.length < 300) {

      const documentDetails = {
        productName: pageData.edpProduct?.name,
        documentLink: router.query?.ref,
      }
      promoteCTAAutoOpen = (
        <PromoteCTA
          ctaText={''}
          documentDetails={documentDetails}
          type={'button'}
          autoOpen={true}
          promoteSuccessHandler={promoteSuccessHandler}
        />
      )
    }
  }

  return (
    <>
      <div className={styles.productHeroBanner}>
        <div className={styles.breadcrumb}>
         <Breadcrumb items={breadcrumbItems} bgEven={true}/>
        </div>
      <USBGrid
        gridGap={defaultGridOptions.gridGap}
        alignItems={defaultGridOptions.alignItems}
        columnCount={defaultGridOptions.columnCount}
        justifyContent={defaultGridOptions.justifyContent}
        display={defaultGridOptions.display}
        padding={defaultGridOptions.padding}
        backgroundColor="grey10"
        addClasses={styles.maingridStyles}
      >
        <USBColumn layoutOpts={twelveColsOption}>
          {showSuccessMessage && (
            <>
              <USBNotification
                variant="confirmation"
                id="promote_cta_success"
                iconAssistiveText={{ label: 'Confirmation' }}
                dataTestId="promote_cta_api-success-notification"
                notificationData={[
                  {
                    text: "Your request has been sent and is in review. You'll receive an email within the next business day with further instructions.",
                  },
                ]}
              />
            </>
          )}
        </USBColumn>
      </USBGrid>
      <USBGrid
        gridGap={defaultGridOptions.gridGap}
        alignItems={defaultGridOptions.alignItems}
        columnCount={defaultGridOptions.columnCount}
        justifyContent={defaultGridOptions.justifyContent}
        display={defaultGridOptions.display}
        padding={defaultGridOptions.padding}
        backgroundColor="grey10"
        addClasses={styles.mainGridStyles}
      >
        <USBColumn
          layoutOpts={contentContainer}
          addClasses={styles.fullWidthColumn}
        >
          <USBGrid
            gridGap={defaultGridOptions.gridGap}
            alignItems={defaultGridOptions.alignItems}
            columnCount="12"
            justifyContent={defaultGridOptions.justifyContent}
            display={defaultGridOptions.display}
            padding="zero"
            addClasses={styles.gridStyles}
          >
            <USBColumn layoutOpts={heroTitleBlock}>
              <p data-testid="productVersion" className={styles.version}>
                VERSION {pageData?.edpProduct?.versionNumber.toUpperCase()}
              </p>
              <h1 className={styles.markdownTitle} data-testid="productName">
                {' '}
                <ReactMarkDown content={pageData?.edpProduct?.name} />
              </h1>
              <div
                data-testid="productDesc"
                dangerouslySetInnerHTML={{
                  __html: pageData?.edpProduct?.description,
                }}
              ></div>
              <div className={styles.buttonsStyles}>
                {promoteCTAAutoOpen}
                <ProductHeroButtonWrapper
                  apiProductDetails={productDetails}
                  enableDocumentationBtn={documentationBtnFlag}
                  promoteSuccessHandler={promoteSuccessHandler}
                  sideNavData={sideNavData}
                ></ProductHeroButtonWrapper>
              </div>
            </USBColumn>
            <USBColumn
              layoutOpts={heroImageBlock}
              addClasses={styles.heroImageBlock}
            >
              <div data-testid="productImage" className={process.env.CONTENT_BRAND === 'elan' ? styles.heroImageWrapper: ''}>
                <img
                  src={imageSrc + pageData?.edpProduct?.thumbnailImage}
                  alt={pageData?.edpProduct?.name || ''} // Using title of the product as alt text, since image alt is not available
                />
              </div>
            </USBColumn>
          </USBGrid>
        </USBColumn>
      </USBGrid>
      </div>
      <USBGrid
        gridGap={defaultGridOptions.gridGap}
        alignItems={defaultGridOptions.alignItems}
        columnCount={defaultGridOptions.columnCount}
        justifyContent={defaultGridOptions.justifyContent}
        display={defaultGridOptions.display}
        padding={defaultGridOptions.padding}
        addClasses={styles.bottomGridContainer}
      >
        <USBColumn layoutOpts={contentContainer}>
          <USBGrid
            gridGap="normal"
            alignItems={defaultGridOptions.alignItems}
            columnCount="12"
            justifyContent={defaultGridOptions.justifyContent}
            display={defaultGridOptions.display}
            padding="zero"
            addClasses={styles.gridStyles}
          >
            <USBColumn
              layoutOpts={contentColumn}
              className={styles.markdown_left_column + " "+ styles.markdownContent}
              dataTestId={'productBodyContent1'}
            >
              {content1}
            </USBColumn>
            <USBColumn
              layoutOpts={rightSidebarColumn}
              addClasses={styles.rightSidebarColumn}
            >
              <h2
                className={styles.rightSideBarLabel}
                data-testid={'rightSideBarVersionTitle'}
              >
                {t('previousVersions', {translation, lng: locale})}
              </h2>
              <div className={styles.dropdownLayout}>
                <USBDropdown
                  dropdownType="text"
                  labelText=""
                  listDirection="bottom"
                  addClasses={styles.productOverviewDropdown}
                  items={displayVersions || [
                    {
                        "id": 1,
                        "value": `${pageData?.edpProduct?.name}  v1`,
                        "isSelectable": true
                    }
                  ]}
                  defaultSelected={
                    pageData?.edpProduct?.name +
                    ' ' +
                    defaultVersion
                  }
                  dataTestId={'VersionList'}
                  handleChange={(e: any) => {
                    let path = ''
                    if(router.asPath.split("/").length === 4) {
                      path = "/" +
                      router.asPath.split("/")[1] +
                      "/" +
                      router.asPath.split("/")[2] +
                      "/" +
                      e.target.outerText.split(pageData?.metaData?.name)[1].trim()
                    } else {
                      path = "/" +
                      router.asPath.split("/")[1] +
                      "/" +
                      router.asPath.split("/")[2] +
                      "/" +
                      router.asPath.split("/")[3] +
                      "/" +
                      e.target.outerText.split(pageData?.metaData?.name)[1].trim()
                    }
                    e.preventDefault()
                    window.location.href = path;
                  }}
                ></USBDropdown>
              </div>

              <h2
                className={styles.rightSideBarLabel}
                data-testid={'rightSideBarDocumentLabel'}
              >
                {rightSideBarDocumentLabel}
              </h2>
              <div data-testid={'rightSideBarDocuments'}>
                {productDocuments}
              </div>
            </USBColumn>
            <USBColumn
              layoutOpts={contentColumn}
              className={styles.markdown_left_column + " " + styles.markdownContent}
              dataTestId={'productBodyContent2'}
            >
              {content2}
            </USBColumn>
          </USBGrid>
        </USBColumn>
      </USBGrid>
    </>
  )
}

export default ProductOverviewPage