import '@usb-shield/react-grid/dist/library/styles/index.css'
import { TextBlockData } from './textBlock.model'
import '@usb-shield/react-grid/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import { defaultGridOptions } from '@/utils/usb-grid.util'
import styles from '@/components/TextBlock/textBlock.module.scss'
import '@usb-shield/react-link/dist/library/styles/index.css'
import dynamic from 'next/dynamic';
const ReactMarkDown = dynamic(() => import("@/components/ReactMarkDown/ReactMarkDown"), {
  ssr: false,
  });
const textColumn = {
  span: null,
  spans: {
    xlarge: 10,
    large: 10,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
}

export const TextBlockProductSections = ({ title, shortDescription, gridOption }: TextBlockData) => {

  let columnCount = defaultGridOptions.columnCount
  if(gridOption && gridOption == 'TenCol'){
    columnCount = '10'
  }

  return (
    <>
      <USBColumn layoutOpts={textColumn} addClasses={styles.fullWidth}>
        {title?.trim() && (
          <h2 className={styles.disclosureHeader} data-testid="title">
            {title}
          </h2>
        )}
      </USBColumn>
      <USBColumn layoutOpts={textColumn} addClasses={styles.fullWidth}>
        {shortDescription && (
          <div
            className={styles.shortDescription}
            data-testid="shortDescription"
          ><ReactMarkDown content={shortDescription} /></div>
        )}
      </USBColumn>
    </>
  )
}
