import { ProductDropdownElement } from "@/modules/product_list/productlist.model"
import { UpdatedSandboxProject } from "@/modules/credentials/projects/projectDetails/projectDetails.model"

export const getProjectDropdownDataAndConsumerKey = (
  currentProductName: string,
  isSuccess: boolean, 
  projectList: UpdatedSandboxProject[] | undefined
) => {
  
  if (isSuccess && projectList && projectList.length > 0) {
    
    // filter out projects where the current product is existing
    let projectsConsumerKey: any = {}
    const projectDropDownData: ProductDropdownElement[] = projectList
      .filter((project) => project.apiProducts.findIndex(p => p.apiproduct === currentProductName) === -1)
      .map(project => {
        projectsConsumerKey[`${project.projectName}`] = project.credentials.consumerKey
        return {
          content: project.displayName,
          value: project.projectName
        }
      })

    return { projectDropDownData: projectDropDownData, projectsConsumerKey: projectsConsumerKey}
  }
  return { projectDropDownData: [] }
}
