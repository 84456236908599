import axios from 'axios'
import { AxiosResponse } from 'axios'

export async function sandboxDropdownChangeCb(event: any, locale: any) {
  const basePath =
      process.env.CONTENT_DOMAIN +
      '/' +
      process.env.CONTENT_BASE_PATH +
      locale +
      '/' +
      process.env.CONTENT_BRAND,
    PATH_ARR = window.location.pathname.split('/'),
    VERSION = event.target.outerText.split('Version:')[1].trim();
    let REQUEST_PATH;
    if(PATH_ARR.length === 5) {
      REQUEST_PATH = `${basePath}/${PATH_ARR[1]}/${PATH_ARR[2]}/${VERSION}.sidenavdata.json`;
    } else {
      REQUEST_PATH = `${basePath}/${PATH_ARR[1]}/${PATH_ARR[3]}/${VERSION}.sidenavdata.json`;
    }
    
    const headers = {
      'content-type': 'application/json',
    }

  let loc: string,
    page: string = PATH_ARR[4],
    res: AxiosResponse,
    versionLinks: any

  event.preventDefault()

  res = await axios.get(REQUEST_PATH, {
    headers,
    proxy: false,
  })

  versionLinks = res.data.map((ele: any) => {
    return ele?.path
  })

  if (res?.data[0]?.path) {
    page = res.data[0].path.split('/').pop() || ''
  }

  versionLinks.forEach((element: string) => {
    if (element.includes(PATH_ARR[4])) {
      page = PATH_ARR[4]
    }
  })

  loc = '/' + PATH_ARR[1] + '/' + PATH_ARR[2] + '/' + PATH_ARR[3] + '/' + VERSION + '/' + page
  if(PATH_ARR.length === 5) {
    loc = '/' + PATH_ARR[1] + '/' + PATH_ARR[2] + '/' + VERSION + '/' + page;
  } else {
    loc = '/' + PATH_ARR[1] + '/' + PATH_ARR[2] + '/' + PATH_ARR[3] + '/' + VERSION + '/' + page;
  }

  window.location.href = loc

  return loc
}
