import { GenericSection } from '@/components/GenericSection/genericSection.model'
import { TextBlock } from '@/components/TextBlock/TextBlock'
import { TextBlockProductSections } from '@/components/TextBlock/TextBlockProductSections'
import React from 'react'

const GenericSection = ({
  heading,
  bodyFormat,
  body,
  displayTOC,
  gridOption
}: GenericSection) => {

  if (gridOption && gridOption == 'TenCol'){
    return (
      <TextBlockProductSections
        title={heading}
        shortDescription={body}
        gridOption={gridOption}
      />
  )
  }else {
    return (
      <TextBlock
        title={heading}
        shortDescription={body}
      />
  )
  }


}

export default GenericSection