import '@usb-shield/react-grid/dist/library/styles/index.css'
import { TextBlockData } from './textBlock.model'
import '@usb-shield/react-grid/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import { defaultGridOptions } from '@/utils/usb-grid.util'
import styles from '@/components/TextBlock/textBlock.module.scss'
import '@usb-shield/react-link/dist/library/styles/index.css'

const textColumn = {
  span: null,
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
}

export const TextBlock = ({ title, shortDescription, gridOption }: TextBlockData) => {

  let columnCount = '16'
  if(gridOption && gridOption == 'TenCol'){
    columnCount = '10'
  } else {
    columnCount = defaultGridOptions.columnCount
  }

  return (
    <USBGrid
      gridGap={defaultGridOptions.gridGap}
      alignItems={defaultGridOptions.alignItems}
      columnCount={columnCount}
      justifyContent={defaultGridOptions.justifyContent}
      display={defaultGridOptions.display}
      padding={defaultGridOptions.padding}
      addClasses={styles.gridWrapper}
    >
      <USBColumn layoutOpts={textColumn} addClasses={styles.fullWidth}>
        {title?.trim() && (
          <h2 className={styles.disclosureHeader} data-testid="title">
            {title}
          </h2>
        )}
      </USBColumn>
      <USBColumn layoutOpts={textColumn} addClasses={styles.fullWidth}>
        {shortDescription && (
          <div
            className={styles.disclosureShortDescription}
            data-testid="shortDescription"
            dangerouslySetInnerHTML={{ __html: shortDescription }}
          ></div>
        )}
      </USBColumn>
    </USBGrid>
  )
}
