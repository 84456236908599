import { tocData } from '@/modules/products/ProductDocumentPage/components/TableOfContent/tableOfContent.model'
import Link from 'next/link'
import styles from '../tableOfContent.module.scss'

const TocMenuItem = ({
  item,
  maxDepth,
  activeAnchor,
  onItemClick
}: {
  item: tocData<string>,
  maxDepth: number,
  activeAnchor: string,
  onItemClick: any
}) => {
  const hasChildren = item.children && item.children.length > 0
  const children = item.children

  let tocLink: string = item.value.replace(/ /g, '-')
  tocLink = tocLink.replace(/[(),:\/}{]/g, '')
  tocLink = tocLink.toLowerCase()

  let hrefStyle = ''

  if (activeAnchor === '#') {
    hrefStyle = `${styles.tableOfContentChildren}`
  }

  if (tocLink === activeAnchor) {
    if (item.depth >= maxDepth) {
      hrefStyle = `${styles.selectedTableOfContentTitle} ${styles.tableOfContentChildren}`
    }
  } else if (item.depth > maxDepth) {
    hrefStyle = `${styles.tableOfContentChildren}`
  }

  return (
    <>
      <li key={`${tocLink}-${item.depth}`}>
        <Link href='/'>
          <a href={`#${tocLink}`}
             data-testid={'tocLink'}
             onClick={(e) => onItemClick(e, tocLink)}
             className={hrefStyle}
          >
            {item.value}
          </a>
        </Link>
      </li>
      {hasChildren && item.depth <= maxDepth && (
        <li key={`${tocLink}-${item.depth}`}>
          <ul className={styles.childrenContainer}>
            {children?.map((item: tocData<string>) => {
              return (
                <TocMenuItem
                  item={item}
                  onItemClick={onItemClick}
                  activeAnchor={activeAnchor}
                  maxDepth={maxDepth}
                />
              )
            })}
          </ul>
        </li>
      )}
    </>
  )
}

export default TocMenuItem
